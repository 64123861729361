/* tslint:disable */
/* eslint-disable */
/**
 * Talardnad API
 * API user management Server by Fiber | Doc by Swagger.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DtosBookingDtos
 */
export interface DtosBookingDtos {
    /**
     * 
     * @type {Array<string>}
     * @memberof DtosBookingDtos
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DtosBookingRequest
 */
export interface DtosBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof DtosBookingRequest
     */
    'booking_date': string;
    /**
     * Required, selected by the user
     * @type {string}
     * @memberof DtosBookingRequest
     */
    'market_id': string;
    /**
     * 
     * @type {EntitiesMethod}
     * @memberof DtosBookingRequest
     */
    'method': EntitiesMethod;
    /**
     * 
     * @type {number}
     * @memberof DtosBookingRequest
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingRequest
     */
    'slot_id'?: string;
    /**
     * Required, selected by the user
     * @type {string}
     * @memberof DtosBookingRequest
     */
    'vendor_id': string;
}


/**
 * 
 * @export
 * @interface DtosBookingResponse
 */
export interface DtosBookingResponse {
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'bookingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'image'?: string;
    /**
     * 
     * @type {EntitiesMethod}
     * @memberof DtosBookingResponse
     */
    'method'?: EntitiesMethod;
    /**
     * 
     * @type {number}
     * @memberof DtosBookingResponse
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'slotId'?: string;
    /**
     * 
     * @type {EntitiesBookingStatus}
     * @memberof DtosBookingResponse
     */
    'status'?: EntitiesBookingStatus;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'transactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosBookingResponse
     */
    'vendorId'?: string;
}


/**
 * 
 * @export
 * @interface DtosCancelBookingRequest
 */
export interface DtosCancelBookingRequest {
    /**
     * The ID of the booking to be canceled.
     * @type {string}
     * @memberof DtosCancelBookingRequest
     */
    'booking_id': string;
    /**
     * Optional: The ID of the user requesting the cancellation.
     * @type {string}
     * @memberof DtosCancelBookingRequest
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface DtosGetListMarketResponse
 */
export interface DtosGetListMarketResponse {
    /**
     * 
     * @type {Array<DtosMarketResponse>}
     * @memberof DtosGetListMarketResponse
     */
    'market'?: Array<DtosMarketResponse>;
}
/**
 * 
 * @export
 * @interface DtosGetUserResponse
 */
export interface DtosGetUserResponse {
    /**
     * 
     * @type {DtosBookingDtos}
     * @memberof DtosGetUserResponse
     */
    'bookings'?: DtosBookingDtos;
    /**
     * 
     * @type {string}
     * @memberof DtosGetUserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosGetUserResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosGetUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosGetUserResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosGetUserResponse
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface DtosLayoutRequest
 */
export interface DtosLayoutRequest {
    /**
     * 
     * @type {Array<DtosZoneLayout>}
     * @memberof DtosLayoutRequest
     */
    'layout'?: Array<DtosZoneLayout>;
}
/**
 * 
 * @export
 * @interface DtosMarketEditRequest
 */
export interface DtosMarketEditRequest {
    /**
     * Required, address of the market
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'address': string;
    /**
     * Required, closing time in HH:mm format
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'close_time': string;
    /**
     * Optional, description of the market
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'description'?: string;
    /**
     * Optional, URL or path to the market image
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'image'?: string;
    /**
     * Optional, latitude coordinate
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'latitude'?: string;
    /**
     * Optional, URL or path to the market layout image
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'layout_image'?: string;
    /**
     * Optional, longitude coordinate
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'longitude'?: string;
    /**
     * Required, name of the market
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'name': string;
    /**
     * Required, opening time in HH:mm format
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'open_time': string;
    /**
     * Optional, phone number of the market
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'phone'?: string;
    /**
     * Required, UUID of the provider
     * @type {string}
     * @memberof DtosMarketEditRequest
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface DtosMarketProviderRequest
 */
export interface DtosMarketProviderRequest {
    /**
     * Required, email address of the provider
     * @type {string}
     * @memberof DtosMarketProviderRequest
     */
    'email': string;
    /**
     * Required, password of the provider
     * @type {string}
     * @memberof DtosMarketProviderRequest
     */
    'password': string;
    /**
     * Required, phone number of the provider
     * @type {string}
     * @memberof DtosMarketProviderRequest
     */
    'phone': string;
    /**
     * Required, username of the provider
     * @type {string}
     * @memberof DtosMarketProviderRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface DtosMarketRequest
 */
export interface DtosMarketRequest {
    /**
     * Required, address of the market
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'address': string;
    /**
     * Required, closing time in HH:mm format
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'close_time': string;
    /**
     * Optional, description of the market
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'description'?: string;
    /**
     * Optional, URL or path to the market image
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'image'?: string;
    /**
     * Optional, latitude coordinate
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'latitude'?: string;
    /**
     * Optional, longitude coordinate
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'longitude'?: string;
    /**
     * Required, name of the market
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'name': string;
    /**
     * Required, opening time in HH:mm format
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'open_time': string;
    /**
     * Required, UUID of the provider
     * @type {string}
     * @memberof DtosMarketRequest
     */
    'provider_id': string;
}
/**
 * 
 * @export
 * @interface DtosMarketResponse
 */
export interface DtosMarketResponse {
    /**
     * the data to be returned
     * @type {Array<EntitiesMarket>}
     * @memberof DtosMarketResponse
     */
    'data'?: Array<EntitiesMarket>;
    /**
     * message to accompany the response
     * @type {string}
     * @memberof DtosMarketResponse
     */
    'message'?: string;
    /**
     * success or error
     * @type {string}
     * @memberof DtosMarketResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface DtosProviderLoginRequest
 */
export interface DtosProviderLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof DtosProviderLoginRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof DtosProviderLoginRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface DtosProviderLoginResponse
 */
export interface DtosProviderLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof DtosProviderLoginResponse
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosProviderLoginResponse
     */
    'provider_id'?: string;
}
/**
 * 
 * @export
 * @interface DtosRegisterRequest
 */
export interface DtosRegisterRequest {
    /**
     * Required, must be a valid email format
     * @type {string}
     * @memberof DtosRegisterRequest
     */
    'email': string;
    /**
     * Required, min 3, max 50 characters
     * @type {string}
     * @memberof DtosRegisterRequest
     */
    'firstname': string;
    /**
     * Required, min 3, max 50 characters
     * @type {string}
     * @memberof DtosRegisterRequest
     */
    'lastname': string;
    /**
     * Required, min 8 characters for password
     * @type {string}
     * @memberof DtosRegisterRequest
     */
    'password': string;
    /**
     * Required, adjust based on the expected format=
     * @type {string}
     * @memberof DtosRegisterRequest
     */
    'phone_number': string;
    /**
     * Required, min 3, max 50 characters
     * @type {string}
     * @memberof DtosRegisterRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface DtosRegisterResponse
 */
export interface DtosRegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof DtosRegisterResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosRegisterResponse
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosRegisterResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosRegisterResponse
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosRegisterResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DtosRegisterResponse
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface DtosSlotUpdateDTO
 */
export interface DtosSlotUpdateDTO {
    /**
     * 
     * @type {EntitiesCategory}
     * @memberof DtosSlotUpdateDTO
     */
    'category'?: EntitiesCategory;
    /**
     * 
     * @type {number}
     * @memberof DtosSlotUpdateDTO
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtosSlotUpdateDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtosSlotUpdateDTO
     */
    'price'?: number;
    /**
     * 
     * @type {EntitiesSlotStatus}
     * @memberof DtosSlotUpdateDTO
     */
    'status'?: EntitiesSlotStatus;
    /**
     * 
     * @type {number}
     * @memberof DtosSlotUpdateDTO
     */
    'width'?: number;
}


/**
 * 
 * @export
 * @interface DtosStall
 */
export interface DtosStall {
    /**
     * 
     * @type {number}
     * @memberof DtosStall
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtosStall
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtosStall
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof DtosStall
     */
    'stallType'?: string;
    /**
     * 
     * @type {number}
     * @memberof DtosStall
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface DtosZoneLayout
 */
export interface DtosZoneLayout {
    /**
     * 
     * @type {string}
     * @memberof DtosZoneLayout
     */
    'date'?: string;
    /**
     * 
     * @type {Array<DtosStall>}
     * @memberof DtosZoneLayout
     */
    'stalls'?: Array<DtosStall>;
    /**
     * 
     * @type {string}
     * @memberof DtosZoneLayout
     */
    'zone'?: string;
}
/**
 * 
 * @export
 * @interface EntitiesBooking
 */
export interface EntitiesBooking {
    /**
     * Changed from Date to BookingDate
     * @type {string}
     * @memberof EntitiesBooking
     */
    'booking_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'market_id'?: string;
    /**
     * 
     * @type {EntitiesMethod}
     * @memberof EntitiesBooking
     */
    'method'?: EntitiesMethod;
    /**
     * 
     * @type {EntitiesPayment}
     * @memberof EntitiesBooking
     */
    'payment'?: EntitiesPayment;
    /**
     * 
     * @type {number}
     * @memberof EntitiesBooking
     */
    'price'?: number;
    /**
     * 
     * @type {EntitiesSlot}
     * @memberof EntitiesBooking
     */
    'slot'?: EntitiesSlot;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'slot_id'?: string;
    /**
     * 
     * @type {EntitiesBookingStatus}
     * @memberof EntitiesBooking
     */
    'status'?: EntitiesBookingStatus;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'updated_at'?: string;
    /**
     * 
     * @type {EntitiesVendor}
     * @memberof EntitiesBooking
     */
    'vendor'?: EntitiesVendor;
    /**
     * 
     * @type {string}
     * @memberof EntitiesBooking
     */
    'vendor_id'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitiesBookingStatus = {
    StatusPending: 'pending',
    StatusCancelled: 'cancelled',
    StatusCompleted: 'completed',
    StatusRefunded: 'refund'
} as const;

export type EntitiesBookingStatus = typeof EntitiesBookingStatus[keyof typeof EntitiesBookingStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitiesCategory = {
    CategoryClothes: 'clothes',
    CategoryFood: 'food',
    CategoryCrafts: 'crafts',
    CategoryProduce: 'produce',
    CategoryElectronics: 'electronics',
    CategoryServices: 'services',
    CategoryOther: 'other'
} as const;

export type EntitiesCategory = typeof EntitiesCategory[keyof typeof EntitiesCategory];


/**
 * 
 * @export
 * @interface EntitiesDashboardResponse
 */
export interface EntitiesDashboardResponse {
    /**
     * Changed to slice
     * @type {Array<EntitiesMarketDashboardStats>}
     * @memberof EntitiesDashboardResponse
     */
    'stats'?: Array<EntitiesMarketDashboardStats>;
}
/**
 * 
 * @export
 * @interface EntitiesLoginRequest
 */
export interface EntitiesLoginRequest {
    /**
     * Password must be provided and at least 8 characters long
     * @type {string}
     * @memberof EntitiesLoginRequest
     */
    'password': string;
    /**
     * Accepts either username or email
     * @type {string}
     * @memberof EntitiesLoginRequest
     */
    'username_or_email': string;
}
/**
 * 
 * @export
 * @interface EntitiesLoginResponse
 */
export interface EntitiesLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof EntitiesLoginResponse
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesLoginResponse
     */
    'vendor_id'?: string;
}
/**
 * 
 * @export
 * @interface EntitiesMarket
 */
export interface EntitiesMarket {
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'close_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'layout_image'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'open_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'phone'?: string;
    /**
     * 
     * @type {EntitiesMarketProvider}
     * @memberof EntitiesMarket
     */
    'provider'?: EntitiesMarketProvider;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'provider_id'?: string;
    /**
     * 
     * @type {Array<EntitiesSlot>}
     * @memberof EntitiesMarket
     */
    'slots'?: Array<EntitiesSlot>;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarket
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface EntitiesMarketDashboardStats
 */
export interface EntitiesMarketDashboardStats {
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'booking_growth'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketDashboardStats
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketDashboardStats
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketDashboardStats
     */
    'market_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'occupancy_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'revenue_growth'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketDashboardStats
     */
    'top_zone'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'top_zone_occupancy'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'total_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'total_cancel_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'total_confirm_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'total_pending_bookings'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntitiesMarketDashboardStats
     */
    'total_revenue'?: number;
}
/**
 * 
 * @export
 * @interface EntitiesMarketProvider
 */
export interface EntitiesMarketProvider {
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'id'?: string;
    /**
     * 
     * @type {Array<EntitiesMarket>}
     * @memberof EntitiesMarketProvider
     */
    'markets'?: Array<EntitiesMarket>;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesMarketProvider
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EntitiesMethod = {
    MethodPromptPay: 'PromptPay'
} as const;

export type EntitiesMethod = typeof EntitiesMethod[keyof typeof EntitiesMethod];


/**
 * 
 * @export
 * @interface EntitiesPayment
 */
export interface EntitiesPayment {
    /**
     * 
     * @type {EntitiesBooking}
     * @memberof EntitiesPayment
     */
    'booking'?: EntitiesBooking;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'booking_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'id'?: string;
    /**
     * 
     * @type {EntitiesMethod}
     * @memberof EntitiesPayment
     */
    'method'?: EntitiesMethod;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'payment_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesPayment
     */
    'price'?: number;
    /**
     * 
     * @type {EntitiesPaymentStatus}
     * @memberof EntitiesPayment
     */
    'status'?: EntitiesPaymentStatus;
    /**
     * 
     * @type {Array<EntitiesTransaction>}
     * @memberof EntitiesPayment
     */
    'transactions'?: Array<EntitiesTransaction>;
    /**
     * 
     * @type {string}
     * @memberof EntitiesPayment
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitiesPaymentStatus = {
    PaymentPending: 'pending',
    PaymentCompleted: 'completed',
    PaymentFailed: 'failed',
    PaymentRefunded: 'refund'
} as const;

export type EntitiesPaymentStatus = typeof EntitiesPaymentStatus[keyof typeof EntitiesPaymentStatus];


/**
 * 
 * @export
 * @interface EntitiesSlot
 */
export interface EntitiesSlot {
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'Booker'?: string;
    /**
     * 
     * @type {Array<EntitiesBooking>}
     * @memberof EntitiesSlot
     */
    'booking'?: Array<EntitiesBooking>;
    /**
     * 
     * @type {EntitiesCategory}
     * @memberof EntitiesSlot
     */
    'category'?: EntitiesCategory;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesSlot
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'market_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesSlot
     */
    'price'?: number;
    /**
     * 
     * @type {EntitiesSlotStatus}
     * @memberof EntitiesSlot
     */
    'status'?: EntitiesSlotStatus;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'updated_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesSlot
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntitiesSlot
     */
    'zone'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitiesSlotStatus = {
    StatusAvailable: 'available',
    StatusBooked: 'booked',
    StatusMaintenance: 'maintenance'
} as const;

export type EntitiesSlotStatus = typeof EntitiesSlotStatus[keyof typeof EntitiesSlotStatus];


/**
 * 
 * @export
 * @interface EntitiesTransaction
 */
export interface EntitiesTransaction {
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'expires_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'method'?: string;
    /**
     * 
     * @type {EntitiesPayment}
     * @memberof EntitiesTransaction
     */
    'payment'?: EntitiesPayment;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'payment_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntitiesTransaction
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'ref1'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'ref2'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'ref3'?: string;
    /**
     * 
     * @type {EntitiesTransactionStatus}
     * @memberof EntitiesTransaction
     */
    'status'?: EntitiesTransactionStatus;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'transaction_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'transaction_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesTransaction
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitiesTransactionStatus = {
    TransactionPending: 'pending',
    TransactionCompleted: 'completed',
    TransactionFailed: 'failed',
    TransactionRefunded: 'refund'
} as const;

export type EntitiesTransactionStatus = typeof EntitiesTransactionStatus[keyof typeof EntitiesTransactionStatus];


/**
 * 
 * @export
 * @interface EntitiesVendor
 */
export interface EntitiesVendor {
    /**
     * 
     * @type {Array<EntitiesBooking>}
     * @memberof EntitiesVendor
     */
    'bookings'?: Array<EntitiesBooking>;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntitiesVendor
     */
    'username'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login with the provided credentials
         * @summary Login
         * @param {EntitiesLoginRequest} login Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost: async (login: EntitiesLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('authLoginPost', 'login', login)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login for market providers with the provided credentials
         * @summary Provider Login
         * @param {DtosProviderLoginRequest} login Provider Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderLoginPost: async (login: DtosProviderLoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('authProviderLoginPost', 'login', login)
            const localVarPath = `/auth/provider/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new market provider with the provided data
         * @summary Register Provider
         * @param {DtosMarketProviderRequest} register Register provider request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderRegisterPost: async (register: DtosMarketProviderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'register' is not null or undefined
            assertParamExists('authProviderRegisterPost', 'register', register)
            const localVarPath = `/auth/provider/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new user with the provided data
         * @summary Register
         * @param {DtosRegisterRequest} register Register request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost: async (register: DtosRegisterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'register' is not null or undefined
            assertParamExists('authRegisterPost', 'register', register)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Login with the provided credentials
         * @summary Login
         * @param {EntitiesLoginRequest} login Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginPost(login: EntitiesLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Login for market providers with the provided credentials
         * @summary Provider Login
         * @param {DtosProviderLoginRequest} login Provider Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authProviderLoginPost(login: DtosProviderLoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosProviderLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authProviderLoginPost(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authProviderLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Register a new market provider with the provided data
         * @summary Register Provider
         * @param {DtosMarketProviderRequest} register Register provider request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authProviderRegisterPost(register: DtosMarketProviderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesMarketProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authProviderRegisterPost(register, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authProviderRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Register a new user with the provided data
         * @summary Register
         * @param {DtosRegisterRequest} register Register request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRegisterPost(register: DtosRegisterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRegisterPost(register, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Login with the provided credentials
         * @summary Login
         * @param {EntitiesLoginRequest} login Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(login: EntitiesLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesLoginResponse> {
            return localVarFp.authLoginPost(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Login for market providers with the provided credentials
         * @summary Provider Login
         * @param {DtosProviderLoginRequest} login Provider Login data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderLoginPost(login: DtosProviderLoginRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtosProviderLoginResponse> {
            return localVarFp.authProviderLoginPost(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new market provider with the provided data
         * @summary Register Provider
         * @param {DtosMarketProviderRequest} register Register provider request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderRegisterPost(register: DtosMarketProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesMarketProvider> {
            return localVarFp.authProviderRegisterPost(register, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new user with the provided data
         * @summary Register
         * @param {DtosRegisterRequest} register Register request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost(register: DtosRegisterRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtosRegisterResponse> {
            return localVarFp.authRegisterPost(register, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Login with the provided credentials
     * @summary Login
     * @param {EntitiesLoginRequest} login Login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginPost(login: EntitiesLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLoginPost(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login for market providers with the provided credentials
     * @summary Provider Login
     * @param {DtosProviderLoginRequest} login Provider Login data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authProviderLoginPost(login: DtosProviderLoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authProviderLoginPost(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new market provider with the provided data
     * @summary Register Provider
     * @param {DtosMarketProviderRequest} register Register provider request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authProviderRegisterPost(register: DtosMarketProviderRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authProviderRegisterPost(register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new user with the provided data
     * @summary Register
     * @param {DtosRegisterRequest} register Register request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRegisterPost(register: DtosRegisterRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRegisterPost(register, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancel a booking with the provided data
         * @summary Cancel a booking
         * @param {DtosCancelBookingRequest} booking Booking data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsCancelPatch: async (booking: DtosCancelBookingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('bookingsCancelPatch', 'booking', booking)
            const localVarPath = `/bookings/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new booking with the provided data
         * @summary Create a booking
         * @param {DtosBookingRequest} booking Booking data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsCreatePost: async (booking: DtosBookingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('bookingsCreatePost', 'booking', booking)
            const localVarPath = `/bookings/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a booking with the provided ID
         * @summary Get a booking
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsGetIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsGetIdGet', 'id', id)
            const localVarPath = `/bookings/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get bookings by market with the provided ID
         * @summary Get bookings by market
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsMarketIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsMarketIdGet', 'id', id)
            const localVarPath = `/bookings/market/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get bookings by user with the provided ID
         * @summary Get bookings by user
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsUserIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsUserIdGet', 'id', id)
            const localVarPath = `/bookings/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancel a booking with the provided data
         * @summary Cancel a booking
         * @param {DtosCancelBookingRequest} booking Booking data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsCancelPatch(booking: DtosCancelBookingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsCancelPatch(booking, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.bookingsCancelPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new booking with the provided data
         * @summary Create a booking
         * @param {DtosBookingRequest} booking Booking data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsCreatePost(booking: DtosBookingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsCreatePost(booking, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.bookingsCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a booking with the provided ID
         * @summary Get a booking
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsGetIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsGetIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.bookingsGetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get bookings by market with the provided ID
         * @summary Get bookings by market
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsMarketIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesBooking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsMarketIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.bookingsMarketIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get bookings by user with the provided ID
         * @summary Get bookings by user
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsUserIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesBooking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsUserIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BookingsApi.bookingsUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * Cancel a booking with the provided data
         * @summary Cancel a booking
         * @param {DtosCancelBookingRequest} booking Booking data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsCancelPatch(booking: DtosCancelBookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtosBookingResponse> {
            return localVarFp.bookingsCancelPatch(booking, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new booking with the provided data
         * @summary Create a booking
         * @param {DtosBookingRequest} booking Booking data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsCreatePost(booking: DtosBookingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtosBookingResponse> {
            return localVarFp.bookingsCreatePost(booking, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a booking with the provided ID
         * @summary Get a booking
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsGetIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DtosBookingResponse> {
            return localVarFp.bookingsGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get bookings by market with the provided ID
         * @summary Get bookings by market
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsMarketIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntitiesBooking>> {
            return localVarFp.bookingsMarketIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get bookings by user with the provided ID
         * @summary Get bookings by user
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsUserIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntitiesBooking>> {
            return localVarFp.bookingsUserIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * Cancel a booking with the provided data
     * @summary Cancel a booking
     * @param {DtosCancelBookingRequest} booking Booking data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsCancelPatch(booking: DtosCancelBookingRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsCancelPatch(booking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new booking with the provided data
     * @summary Create a booking
     * @param {DtosBookingRequest} booking Booking data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsCreatePost(booking: DtosBookingRequest, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsCreatePost(booking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a booking with the provided ID
     * @summary Get a booking
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsGetIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get bookings by market with the provided ID
     * @summary Get bookings by market
     * @param {string} id Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsMarketIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsMarketIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get bookings by user with the provided ID
     * @summary Get bookings by user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsUserIdGet(id: string, options?: RawAxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get weekly stats for a market with the market ID
         * @summary Get weekly stats for a market
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardWeeklyIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dashboardWeeklyIdGet', 'id', id)
            const localVarPath = `/dashboard/weekly/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Get weekly stats for a market with the market ID
         * @summary Get weekly stats for a market
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardWeeklyIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardWeeklyIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.dashboardWeeklyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * Get weekly stats for a market with the market ID
         * @summary Get weekly stats for a market
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardWeeklyIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesDashboardResponse> {
            return localVarFp.dashboardWeeklyIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * Get weekly stats for a market with the market ID
     * @summary Get weekly stats for a market
     * @param {string} id Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardWeeklyIdGet(id: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardWeeklyIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MarketApi - axios parameter creator
 * @export
 */
export const MarketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new market
         * @summary Create a new market
         * @param {DtosMarketRequest} market Market object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsCreatePost: async (market: DtosMarketRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'market' is not null or undefined
            assertParamExists('marketsCreatePost', 'market', market)
            const localVarPath = `/markets/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(market, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit a market
         * @summary Edit a market
         * @param {string} id Market ID
         * @param {DtosMarketEditRequest} market Market object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsEditIdPatch: async (id: string, market: DtosMarketEditRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketsEditIdPatch', 'id', id)
            // verify required parameter 'market' is not null or undefined
            assertParamExists('marketsEditIdPatch', 'market', market)
            const localVarPath = `/markets/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(market, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all markets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsGetGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/markets/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a market by ID
         * @summary Get a market by ID
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsGetIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketsGetIdGet', 'id', id)
            const localVarPath = `/markets/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a market by Provider ID
         * @summary Get a market by Provider ID
         * @param {string} id Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsProviderGetIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketsProviderGetIdGet', 'id', id)
            const localVarPath = `/markets/provider/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketApi - functional programming interface
 * @export
 */
export const MarketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new market
         * @summary Create a new market
         * @param {DtosMarketRequest} market Market object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketsCreatePost(market: DtosMarketRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosMarketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketsCreatePost(market, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketApi.marketsCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edit a market
         * @summary Edit a market
         * @param {string} id Market ID
         * @param {DtosMarketEditRequest} market Market object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketsEditIdPatch(id: string, market: DtosMarketEditRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesMarket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketsEditIdPatch(id, market, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketApi.marketsEditIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all markets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketsGetGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosGetListMarketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketsGetGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketApi.marketsGetGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a market by ID
         * @summary Get a market by ID
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketsGetIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesMarket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketsGetIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketApi.marketsGetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a market by Provider ID
         * @summary Get a market by Provider ID
         * @param {string} id Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketsProviderGetIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosMarketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketsProviderGetIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketApi.marketsProviderGetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MarketApi - factory interface
 * @export
 */
export const MarketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketApiFp(configuration)
    return {
        /**
         * Create a new market
         * @summary Create a new market
         * @param {DtosMarketRequest} market Market object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsCreatePost(market: DtosMarketRequest, options?: RawAxiosRequestConfig): AxiosPromise<DtosMarketResponse> {
            return localVarFp.marketsCreatePost(market, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a market
         * @summary Edit a market
         * @param {string} id Market ID
         * @param {DtosMarketEditRequest} market Market object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsEditIdPatch(id: string, market: DtosMarketEditRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesMarket> {
            return localVarFp.marketsEditIdPatch(id, market, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all markets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsGetGet(options?: RawAxiosRequestConfig): AxiosPromise<DtosGetListMarketResponse> {
            return localVarFp.marketsGetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a market by ID
         * @summary Get a market by ID
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsGetIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesMarket> {
            return localVarFp.marketsGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a market by Provider ID
         * @summary Get a market by Provider ID
         * @param {string} id Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketsProviderGetIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DtosMarketResponse> {
            return localVarFp.marketsProviderGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketApi - object-oriented interface
 * @export
 * @class MarketApi
 * @extends {BaseAPI}
 */
export class MarketApi extends BaseAPI {
    /**
     * Create a new market
     * @summary Create a new market
     * @param {DtosMarketRequest} market Market object that needs to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public marketsCreatePost(market: DtosMarketRequest, options?: RawAxiosRequestConfig) {
        return MarketApiFp(this.configuration).marketsCreatePost(market, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit a market
     * @summary Edit a market
     * @param {string} id Market ID
     * @param {DtosMarketEditRequest} market Market object that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public marketsEditIdPatch(id: string, market: DtosMarketEditRequest, options?: RawAxiosRequestConfig) {
        return MarketApiFp(this.configuration).marketsEditIdPatch(id, market, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all markets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public marketsGetGet(options?: RawAxiosRequestConfig) {
        return MarketApiFp(this.configuration).marketsGetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a market by ID
     * @summary Get a market by ID
     * @param {string} id Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public marketsGetIdGet(id: string, options?: RawAxiosRequestConfig) {
        return MarketApiFp(this.configuration).marketsGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a market by Provider ID
     * @summary Get a market by Provider ID
     * @param {string} id Provider ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public marketsProviderGetIdGet(id: string, options?: RawAxiosRequestConfig) {
        return MarketApiFp(this.configuration).marketsProviderGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get payment by the provided ID
         * @summary Get payment by ID
         * @param {string} id Payment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentsGetIdGet', 'id', id)
            const localVarPath = `/payments/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get payment by the provided ID
         * @summary Get payment by ID
         * @param {string} id Payment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsGetIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosBookingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsGetIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.paymentsGetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * Get payment by the provided ID
         * @summary Get payment by ID
         * @param {string} id Payment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsGetIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DtosBookingResponse> {
            return localVarFp.paymentsGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * Get payment by the provided ID
     * @summary Get payment by ID
     * @param {string} id Payment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsGetIdGet(id: string, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SlotsApi - axios parameter creator
 * @export
 */
export const SlotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete slot
         * @summary Delete slot
         * @param {string} id Slot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsDeleteIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsDeleteIdDelete', 'id', id)
            const localVarPath = `/slots/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete slot by date and zone
         * @summary Delete slot by date and zone
         * @param {string} id Slot ID
         * @param {string} zoneID Zone ID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsDeleteIdZoneZoneIDDateDateDelete: async (id: string, zoneID: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsDeleteIdZoneZoneIDDateDateDelete', 'id', id)
            // verify required parameter 'zoneID' is not null or undefined
            assertParamExists('slotsDeleteIdZoneZoneIDDateDateDelete', 'zoneID', zoneID)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('slotsDeleteIdZoneZoneIDDateDateDelete', 'date', date)
            const localVarPath = `/slots/delete/{id}/zone/{zoneID}/date/{date}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"zoneID"}}`, encodeURIComponent(String(zoneID)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit slot
         * @summary Edit slot
         * @param {string} id Slot ID
         * @param {DtosSlotUpdateDTO} updateDTO Slot update data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsEditIdPatch: async (id: string, updateDTO: DtosSlotUpdateDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsEditIdPatch', 'id', id)
            // verify required parameter 'updateDTO' is not null or undefined
            assertParamExists('slotsEditIdPatch', 'updateDTO', updateDTO)
            const localVarPath = `/slots/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all slots
         * @summary Get all slots
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsGetIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsGetIdGet', 'id', id)
            const localVarPath = `/slots/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update layout
         * @summary Create or update layout
         * @param {string} marketId Market ID
         * @param {DtosLayoutRequest} layout Layout data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsMarketIdCreatePost: async (marketId: string, layout: DtosLayoutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketId' is not null or undefined
            assertParamExists('slotsMarketIdCreatePost', 'marketId', marketId)
            // verify required parameter 'layout' is not null or undefined
            assertParamExists('slotsMarketIdCreatePost', 'layout', layout)
            const localVarPath = `/slots/{marketId}/create`
                .replace(`{${"marketId"}}`, encodeURIComponent(String(marketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(layout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get slots by date
         * @summary Get slots by date
         * @param {string} marketID MarketID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsMarketsMarketIDDateDateGet: async (marketID: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marketID' is not null or undefined
            assertParamExists('slotsMarketsMarketIDDateDateGet', 'marketID', marketID)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('slotsMarketsMarketIDDateDateGet', 'date', date)
            const localVarPath = `/slots/markets/{marketID}/date/{date}`
                .replace(`{${"marketID"}}`, encodeURIComponent(String(marketID)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get provider slots
         * @summary Get provider slots
         * @param {string} id Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsProviderGetIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('slotsProviderGetIdGet', 'id', id)
            const localVarPath = `/slots/provider/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlotsApi - functional programming interface
 * @export
 */
export const SlotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlotsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete slot
         * @summary Delete slot
         * @param {string} id Slot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsDeleteIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsDeleteIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsDeleteIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete slot by date and zone
         * @summary Delete slot by date and zone
         * @param {string} id Slot ID
         * @param {string} zoneID Zone ID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsDeleteIdZoneZoneIDDateDateDelete(id: string, zoneID: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsDeleteIdZoneZoneIDDateDateDelete(id, zoneID, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsDeleteIdZoneZoneIDDateDateDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Edit slot
         * @summary Edit slot
         * @param {string} id Slot ID
         * @param {DtosSlotUpdateDTO} updateDTO Slot update data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsEditIdPatch(id: string, updateDTO: DtosSlotUpdateDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesSlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsEditIdPatch(id, updateDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsEditIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all slots
         * @summary Get all slots
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsGetIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntitiesSlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsGetIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsGetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create or update layout
         * @summary Create or update layout
         * @param {string} marketId Market ID
         * @param {DtosLayoutRequest} layout Layout data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsMarketIdCreatePost(marketId: string, layout: DtosLayoutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsMarketIdCreatePost(marketId, layout, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsMarketIdCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get slots by date
         * @summary Get slots by date
         * @param {string} marketID MarketID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsMarketsMarketIDDateDateGet(marketID: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesSlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsMarketsMarketIDDateDateGet(marketID, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsMarketsMarketIDDateDateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get provider slots
         * @summary Get provider slots
         * @param {string} id Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slotsProviderGetIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntitiesSlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.slotsProviderGetIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SlotsApi.slotsProviderGetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SlotsApi - factory interface
 * @export
 */
export const SlotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlotsApiFp(configuration)
    return {
        /**
         * Delete slot
         * @summary Delete slot
         * @param {string} id Slot ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsDeleteIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.slotsDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete slot by date and zone
         * @summary Delete slot by date and zone
         * @param {string} id Slot ID
         * @param {string} zoneID Zone ID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsDeleteIdZoneZoneIDDateDateDelete(id: string, zoneID: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.slotsDeleteIdZoneZoneIDDateDateDelete(id, zoneID, date, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit slot
         * @summary Edit slot
         * @param {string} id Slot ID
         * @param {DtosSlotUpdateDTO} updateDTO Slot update data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsEditIdPatch(id: string, updateDTO: DtosSlotUpdateDTO, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesSlot> {
            return localVarFp.slotsEditIdPatch(id, updateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all slots
         * @summary Get all slots
         * @param {string} id Market ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsGetIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<EntitiesSlot> {
            return localVarFp.slotsGetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update layout
         * @summary Create or update layout
         * @param {string} marketId Market ID
         * @param {DtosLayoutRequest} layout Layout data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsMarketIdCreatePost(marketId: string, layout: DtosLayoutRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.slotsMarketIdCreatePost(marketId, layout, options).then((request) => request(axios, basePath));
        },
        /**
         * Get slots by date
         * @summary Get slots by date
         * @param {string} marketID MarketID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsMarketsMarketIDDateDateGet(marketID: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntitiesSlot>> {
            return localVarFp.slotsMarketsMarketIDDateDateGet(marketID, date, options).then((request) => request(axios, basePath));
        },
        /**
         * Get provider slots
         * @summary Get provider slots
         * @param {string} id Provider ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slotsProviderGetIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntitiesSlot>> {
            return localVarFp.slotsProviderGetIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlotsApi - object-oriented interface
 * @export
 * @class SlotsApi
 * @extends {BaseAPI}
 */
export class SlotsApi extends BaseAPI {
    /**
     * Delete slot
     * @summary Delete slot
     * @param {string} id Slot ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsDeleteIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete slot by date and zone
     * @summary Delete slot by date and zone
     * @param {string} id Slot ID
     * @param {string} zoneID Zone ID
     * @param {string} date Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsDeleteIdZoneZoneIDDateDateDelete(id: string, zoneID: string, date: string, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsDeleteIdZoneZoneIDDateDateDelete(id, zoneID, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit slot
     * @summary Edit slot
     * @param {string} id Slot ID
     * @param {DtosSlotUpdateDTO} updateDTO Slot update data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsEditIdPatch(id: string, updateDTO: DtosSlotUpdateDTO, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsEditIdPatch(id, updateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all slots
     * @summary Get all slots
     * @param {string} id Market ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsGetIdGet(id: string, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update layout
     * @summary Create or update layout
     * @param {string} marketId Market ID
     * @param {DtosLayoutRequest} layout Layout data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsMarketIdCreatePost(marketId: string, layout: DtosLayoutRequest, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsMarketIdCreatePost(marketId, layout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get slots by date
     * @summary Get slots by date
     * @param {string} marketID MarketID
     * @param {string} date Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsMarketsMarketIDDateDateGet(marketID: string, date: string, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsMarketsMarketIDDateDateGet(marketID, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get provider slots
     * @summary Get provider slots
     * @param {string} id Provider ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotsApi
     */
    public slotsProviderGetIdGet(id: string, options?: RawAxiosRequestConfig) {
        return SlotsApiFp(this.configuration).slotsProviderGetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a user with the provided ID
         * @summary Delete a user
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdDelete', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user with the provided ID
         * @summary Get a user by ID
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a user with the provided ID
         * @summary Delete a user
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a user with the provided ID
         * @summary Get a user by ID
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DtosGetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Delete a user with the provided ID
         * @summary Delete a user
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.usersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user with the provided ID
         * @summary Get a user by ID
         * @param {string} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DtosGetUserResponse> {
            return localVarFp.usersIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Delete a user with the provided ID
     * @summary Delete a user
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user with the provided ID
     * @summary Get a user by ID
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdGet(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



